let cache = require('@/plugins/cache.js')

let interpretLogic = function(logic, logics, callBack){
    let logicMap = {}
    for(let i=0; i<logics.length; i++){
        let record = logics[i]
        logicMap[record.id] = record
    }

    if(logic.requirement=='allTrue'){
        let allTrue = logic.rules.length>0
        let displayRules = logic.rules
        
        for(let r=0; r<displayRules.length; r++){
            let displayRule = displayRules[r]
            let rule_id = displayRule.rule_id
            let rule = logicMap[rule_id]

            if(rule){
                // if(rule.result!=true){
                if(rule.result != displayRule.result){
                    allTrue = false
                }
            }else{
                console.error('missing logic: '+rule_id)
                allTrue = false
            }
        }
        callBack({
            type: logic.requirement,
            result: allTrue
        })
    }
}

let initializeQuestions = function(component, questions, logics){
    function patchQuestion(question){
        let newTemplate = component.newTemplate
        if(newTemplate){
            if(question.prompts==undefined){
                question.prompts = []
            }

            if(question.displayLogic!=null && !question.displayLogic.rules){
                question.displayLogic = null
            }
            
            for(let g=0; g<question.groups.length; g++){
                let group = question.groups[g]
                if(group.requirements==undefined){
                    group.requirements = newTemplate('requirements')
                }
                if(group.displayLogic!=null && !group.displayLogic.rules){
                    group.displayLogic = null
                }                

                for(let c=0; c<group.choices.length; c++){
                    let choice = group.choices[c]
                    if(choice.requirements==undefined){
                        choice.requirements = newTemplate('requirements')
                        choice.requirements.required = false
                    }
                    if(choice.displayLogic!=null && !choice.displayLogic.rules){
                        choice.displayLogic = null
                    }                    
                }
            }
        }
    }

    function patchPrompt(prompt){
        if(!prompt.references){
            prompt.references = []
        }
    }

    let prompts = component.prompts
    for(let p=0; p<prompts.length; p++){
        let prompt = prompts[p]
        patchPrompt(prompt)
    }

  component.ready = false
    for(let i=0; i<questions.length; i++){
        let question = questions[i]
        question.form = {}
        question.answered = false,
        question.valid = false
        patchQuestion(question)
        component.$set( component.ui.triggers, question.id, {randomize: 0,reset: 0})
        component.$set(component.questions, i, question)
    }
    setTimeout(function(){
        component.ready = true
    },100)
    setTimeout(function(){
        logicHandler({questionMap:component.questionMap, logicMap:component.logicMap}, logics)
        setTimeout(function(){
            // displayHandler(undefined, logicIndex, component.questionMap)
            // disableHandler(undefined, logicIndex, component.questionMap)
            // promptDisplayHandler(undefined, logicIndex, component.questionMap)
            assetDisplayHandler(component.assetMap)            
            component.$forceUpdate()
        },800)
    },200)
}

let logicHandler = function(sources, logics){
    function hasAnswer(value){
        if(value!=null){
            if(typeof value=='object' && value.length===undefined){
                for(let i in value){
                    let item = value[i]
                    if(item!=null && item.length>0){
                        return true
                    }
                }                
                
            }else if(value.length!=undefined){
                return value.length>0
            }
        }
        return false
    }
    
    for(let i=0; i<logics.length; i++){
        let ruleSet = logics[i]
        // let ruleSet = logicIndex[logicID]
        ruleSet.report = {
            requirement: ruleSet.requirement,
            rules: [],
            result: undefined
        }
        ruleSet.result = undefined



        //allTrue - array of ruleset objects, all rules within ruleset should return true
        let passed = 0
        let failed = 0
        if(ruleSet.requirement=='allTrue'){
            ruleSet.result = true
        }else if(ruleSet.requirement=='anyTrue'){
            ruleSet.result = false
        }else if(ruleSet.requirement=='noneTrue'){
            ruleSet.result = true
        }else if(ruleSet.requirement=='xTrue'){
            ruleSet.result = false
        }

        let rules = ruleSet.rules
        for(let r=0; r<rules.length; r++){
            let rule = rules[r]
            
            if(rule.source.type=='questionMap'){
                let source = rule.source
                let questionMap = sources[source.type]

                if(questionMap[source.input.question_id]){
                    if(questionMap[source.input.question_id].form.group && questionMap[source.input.question_id].form.group[source.input.group_id]){
                        let answer = source.input.choice_id!=null ? questionMap[source.input.question_id].form.group[source.input.group_id].value[source.input.choice_id] : questionMap[source.input.question_id].form.group[source.input.group_id].value
                        let isAnswered = hasAnswer(answer)
                        let helper = "<i>(QID: "+source.input.question_id+" GID: "+source.input.group_id
                        if(source.input.choice_id){
                            helper+=" CID: "+source.input.choice_id
                        }
                        helper+=")</i> <b style='margin: 5px;'>["+rule.operator+"]</b> ==> "+rule.value
                        let input = JSON.parse(JSON.stringify(source.input))
                        input.isAnswered = isAnswered
                        input.answer = answer
                        ruleSet.report.rules[r] = {
                            helper,
                            operator: rule.operator,
                            rule,
                            input,
                            result: false
                        }
        
                        if(!isAnswered){
                            ruleSet.report.rules[r].result = false
                        }else{
                            if(rule.operator=='equals'){
                                if(['string','number'].includes(typeof answer)){
                                    if(['string','number'].includes(typeof rule.value)){
                                        ruleSet.report.rules[r].result = answer == rule.value
                                    }else{
                                        ruleSet.report.rules[r].result = rule.value.includes(answer)
                                    }
                                    
                                }else{
                                    //For checkboxes
                                    let found = false
                                    
                                    for(let i in answer){
                                        let answerValue = answer[i]                                
                                        if(rule.value.includes(answerValue)){
                                            found = true
                                        }
                                    }
                                    ruleSet.report.rules[r].result = found                            
                                }
                            }else if(['gt','lt','gte','lte'].includes(rule.operator)){
                                let answerValue = parseFloat(answer, 10)
                                let pass = false
                                for(let rv in rule.value){
                                    let ruleValue = parseFloat(rule.value[rv], 10)
                                    if(!isNaN(answerValue) && !isNaN(ruleValue)){
                                        switch(rule.operator){
                                            case"gt":
                                                if(answerValue > ruleValue){
                                                    pass = true
                                                }

                                            break;

                                            case"lt":
                                                if(answerValue < ruleValue){
                                                    pass = true
                                                }

                                            break;

                                            case"gte":
                                                if(answerValue >= ruleValue){
                                                    pass = true
                                                }

                                            break;

                                            case"lte":
                                                if(answerValue <= ruleValue){
                                                    pass = true
                                                }

                                            break;
                                        }
                                    }
                                }
                               ruleSet.report.rules[r].result = pass  
                                 
                                

                            }else{
                                console.log('missing operater handler',rule.operator)
                            }
                        }
        
                        // if(!ruleSet.report.rules[r].result){
                        //     ruleSet.result = false
                        // }
    
                    }else{
                        // console.error('Logic references missing question group',{
                        //     questionID: source.input.question_id,
                        //     groupID: source.input.group_id,
                        //     questionMap
                        // })
                    }
                }else{
                    console.error('Logic references missing question',{
                        questionID: source.input.question_id,
                        groupID: source.input.group_id,
                        questionMap
                    })
                }

            }else if(rule.source.type=='otherLogic'){
                for(let valueIndex=0; valueIndex<rule.value.length; valueIndex++){
                    let ruleID = rule.value[valueIndex]
                    if(!(sources.logicMap[ruleID].result===true)){
                        ruleSet.result = false
                    }
                }
            }

            
        }

        for(let r=0; r<ruleSet.report.rules.length; r++){
            let ruleResult = ruleSet.report.rules[r].result

            if(ruleSet.requirement=='allTrue'){
                if(!ruleResult){
                    ruleSet.result = false
                }
            }else if(ruleSet.requirement=='anyTrue'){
                if(ruleResult){
                    ruleSet.result = true
                }
            }else if(ruleSet.requirement=='noneTrue'){
                if(ruleResult){
                    ruleSet.result = false
                }
            }else if(ruleSet.requirement=='xTrue'){
                if(ruleResult){
                    passed++
                }
            }
        }

        // if(ruleSet.requirement=='xTrue'){
        //     ruleSet.result = passed>=parseInt(ruleSet.requirement.x,10)
        // }
    }
    return logics
}

let displayHandler = function(triggerQuestion, logics, questionMap){
        
    for(let qid in questionMap){
        let question = questionMap[qid]
        let logic = question.displayLogic
        if(question.canHide && logic){
            interpretLogic(logic, logics, (response)=>{
                question.display = response.result
            })                
        }

        for(let g=0; g<question.groups.length; g++){
            let group = question.groups[g]
            let groupLogic = group.displayLogic
            if(group.canHide && groupLogic){
                interpretLogic(groupLogic, logics, (response)=>{
                    group.display = response.result
                })
            }

            for(let c=0; c<group.choices.length; c++){
                let choice = group.choices[c]
                let choiceLogic = choice.displayLogic
                if(choice.canHide && choiceLogic){
                    interpretLogic(choiceLogic, logics, (response)=>{
                        choice.display = response.result
                    })
                }                    
            }
        }            
    }


    for(let l=0; l<logics.length ;l++){
        let logic = logics[l]
        let targets = document.querySelectorAll("[logic='"+logic.id+"']")

        for(let i=0; i<targets.length; i++){
            let target = targets[i]
            target.style.display = logic.result ? 'block' : 'none'
        }
    }

}
let disableHandler = function(triggerQuestion, logics, questionMap){

    for(let qid in questionMap){
        let question = questionMap[qid]

        for(let g=0; g<question.groups.length; g++){
            let group = question.groups[g]
            let groupLogic = group.disableLogic
            if(group.canDisable && groupLogic){
                interpretLogic(groupLogic, logics, (response)=>{
                    group.disabled = response.result
                })
            }

            for(let c=0; c<group.choices.length; c++){
                let choice = group.choices[c]
                let choiceLogic = choice.disableLogic
                if(choice.canDisable && choiceLogic){
                    interpretLogic(choiceLogic, logics, (response)=>{
                        choice.disabled = response.result
                    })
                }                    
            }
        }

    }

}
let promptDisplayHandler = function(triggerQuestion, logics, promptMap){
    for(let pid in promptMap){
        let prompt = promptMap[pid]
        let logic = prompt.displayLogic
        interpretLogic(logic, logics, (response)=>{
            prompt.display = response.result
        })                     
    }
}

let assetDisplayHandler = function(assetMap, language='en-ca'){
    let targets = document.querySelectorAll("img[asset]")
    for(let t=0; t<targets.length; t++){
        let target = targets[t]
        let assetID = target.getAttribute('asset')
        target.setAttribute('src',assetMap[assetID].content[language])
    }
}


module.exports = {
    cacheAnswers: function(user_id, questionSet, data){
        let cachedAnswers = cache.storage.local.get('cachedAnswers') ? cache.storage.local.get('cachedAnswers') : {}
        if(!cachedAnswers[user_id]){
            cachedAnswers[user_id] = {}
        }
        cachedAnswers[user_id][questionSet] = data

        cache.storage.local.set('cachedAnswers',cachedAnswers)
    },
    loadAnswers: function(user_id, questionSet, target){
        let cachedAnswers = cache.storage.local.get('cachedAnswers') ? cache.storage.local.get('cachedAnswers') : {}
        let data = (cachedAnswers[user_id] && cachedAnswers[user_id][questionSet]) ? cachedAnswers[user_id][questionSet] : false
        target.time_spent = data.time_spent ? data.time_spent : 0
        target.hub.logs = data.logs ? data.logs : []
        
        for(let qid in data.answers){
            let answers = data.answers[qid]
            let question = target.questionMap[qid]
            if(question){
                for(let gid in answers.group){
                    let group = answers.group[gid]
                    target.questionMap[qid].form.group[gid].value = group.value
                    target.questionMap[qid].form.group[gid].others = group.others
                }
            }
        }
    },
    initializeQuestions,
    logicHandler,
    displayHandler,  
    disableHandler,    
    promptDisplayHandler
}