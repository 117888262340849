<template>
<div>
    <!-- <div class="ranking-group">
        <div class="ranking-text" v-html="group.text[language]"/>
        <div class="ranking-input-container">
            <v-btn v-for="choice in group.choices" v-show="choice.display" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id" :class="['ranking-input',{['selected']:choice.value ? choice.value==form.value : choice.id==form.value}]" @click="select(choice)" :disabled="question.display===false || choice.disabled===true">
                {{choice.text[language]}}
                <v-radio dense :disabled="question.display===false || choice.disabled===true" :value="choice.value ? choice.value : choice.id" :label="choice.text[language]"/>
                <other v-if="form.value==choice.value && choice.other" v-model="form.others[choice.id]" :label="choice.other_text[language]"/>
            </v-btn>
        </div>
    </div> -->
    <v-container class="pa-0" style="margin-top: 25px;">
        <v-row class="pa-0">
            <v-col cols="12" sm="12" md="12" lg="4" class="pa-0">
                <div v-html="group.text[language]"/>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="8" align="center" class="pa-0">
                <div>
                    <v-btn v-for="choice in group.choices" v-show="choice.display" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id" :class="['elevation-1','ranking-input',{['selected']:choice.value ? choice.value==form.value : choice.id==form.value}]" @click="select(choice)" :disabled="question.display===false || choice.disabled===true">
                        {{choice.text[language]}}
                        <!-- <v-radio dense :disabled="question.display===false || choice.disabled===true" :value="choice.value ? choice.value : choice.id" :label="choice.text[language]"/> -->
                        <!-- <other v-if="form.value==choice.value && choice.other" v-model="form.others[choice.id]" :label="choice.other_text[language]"/> -->
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import other from './other'
export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        question: {
            type: Object,
            required: true
        },
        group: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        other
    },
    created: function(){
        this.form = this.$attrs.value
    },
    data: function(){
        return {
            form: {}
        }
    },
    methods: {
        select: function(choice){
            let value = choice.value ? choice.value : choice.id
            this.form.value = value
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.$emit('input',this.form)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.ranking-group{
    display: flex;
    align-items: center;
    justify-content: start;
    // justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
}


.ranking-text{
    width: 50%;
}

.ranking-input-container{
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: end;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 50%;
}
.ranking-input{
    margin: 3px;
    background-color: $primary-light-3 !important;
}

.selected{
    background-color: $primary !important;
    color: white;
}
</style>
