<template>
<div class="prompt" :class="[{'debug-mode':debug}]">
    <!-- <json-viewer v-if="debug" :value="prompt"/> -->
    <h5 v-if="debug" v-html="prompt.description"/>
    <span v-html="prompt.content[language]"/>

    <div class="footer">
        <v-list style="background-color: transparent;">
            <v-list-item v-for="referenceID in prompt.references" :key="'prompt_referernce_'+prompt.id+'_'+referenceID">
                <v-list-item-content>
                    <span v-html="referenceMap[referenceID].content[language]"/>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</div>
</template>

<script>
export default {
    name: "Prompt",
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        assets: {
            type: Array,
            required: false,
            default: function(){
                return []
            }
        },
        prompt: {
            type: Object,
            required: true
        },
        references: {
            type: Array,
            required: false,
            default: function(){
                return []
            }
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        referenceMap: function(){
            let references = this.references
            let output = {}
            for(let i=0; i<references.length; i++){
                let reference = references[i]
                output[reference.id] = reference
            }
            return output
        },
        assetMap: function(){
            let assets = this.assets
            let output = {}
            for(let i=0; i<assets.length; i++){
                let asset = assets[i]
                output[asset.id] = asset
            }
            return output
        }
    }
}
</script>

<style scoped>
.prompt{
    width: 100%;
    min-height: 150px;
}

.footer{
    background-color: transparent;
    margin: 25px;
    border-top: 1px solid whitesmoke;
    font-size: 0.7em;
}
</style>

<style>
.debug-mode [logic]{
    border: 1px solid purple;
    margin: 5px;
}

img[asset]{
    max-width: 100%;
}
</style>