<template>
  <v-text-field v-model="form" @blur="trimAnswer" :label="label"/>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        }
    },
    created: function(){
        this.form = this.$attrs.value
    },
    data: function(){
        return {
            form: null
        }
    },
    methods: {
        trimAnswer: function(){
            this.form = this.form.trim()
        }
    },
    watch: {
        form: function(){
            this.$emit('input',this.form)
        }
    }
}
</script>

<style>

</style>