<template>
<v-container :data-question-id="question.id" :class="['question',{'hidden':!question.display},{'hasDisplayLogics': debug && hasDisplayLogics},{'valid':(debug && question.valid)},{'invalid':(debug && question.display && !question.valid)}]">
    <v-row v-if="debug">
        <v-col>
            QID: {{question.id}}
        </v-col>

        <v-col v-if="debug">
            <v-btn small color="warning" style="margin: 0px 5px 0px 5px;" :disabled="!question.display" @click="randomize">Randomize</v-btn>
            <v-btn small color="primary" :disabled="!question.display" @click="isValid(true)">Debug Validation</v-btn>
            <v-btn small color="error" style="margin: 0px 5px 0px 5px;" :disabled="!question.display" @click="resetForm()">Reset</v-btn>
            <json-viewer v-if="question.requirements" :value="question.requirements" :expand-depth="3"/>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-container>
                <v-row :dense="true" class="question-header">
                    <v-col>
                        <span v-html="question.header.text[language]"/>
                    </v-col>
                </v-row>

                <v-row :dense="true" class="question-body">
                    <v-col>
                        <span v-html="question.body.text[language]"/>
                    </v-col>
                </v-row>

                <v-row :dense="true"  class="question-subtext" v-if="question.subtext.text[language]" style="margin: 5px; font-size: 0.8em;">
                    <v-col>
                        <span v-html="question.subtext.text[language]"/>
                    </v-col>
                </v-row>

                <v-row :dense="true" class="question-group">
                    <v-col class="pa-0">
                        <v-container>
                            <v-row v-for="group in question.groups" v-show="debug || group.display" :class="[{'hidden':!group.display}]" :data-group-id="group.id" :key="'input_qid_'+question.id+'_gid_'+group.id">
                                <v-col>
                                    <checkboxQuestion v-if="group.type=='checkbox'" v-model="form.group[group.id]" :question="question" :debug="debug" :group="group" :language="language"/>
                                    <radioQuestion v-else-if="group.type=='radio'" v-model="form.group[group.id]" :question="question" :debug="debug" :group="group" :language="language"/>
                                    <rankingQuestion v-else-if="group.type=='ranking'" v-model="form.group[group.id]" :question="question" :debug="debug" :group="group" :language="language"/>
                                    <ratingQuestion v-else-if="group.type=='rating'" v-model="form.group[group.id]" :question="question" :debug="debug" :group="group" :language="language"/>
                                    <numberTextQuestion v-else-if="['number','textfield'].includes(group.type)" v-model="form.group[group.id]" :question="question" :debug="debug" :group="group" :language="language"/>
                                    <template v-else>
                                        Unknown group type: {{group.type}}
                                    </template>
                                </v-col>
                            </v-row>
                        </v-container>
                        <!-- <v-list style="background-color: transparent;" class="pa-0" :style="{'margin-top': question.groups[0].type=='checkbox' ? '25px' : ''}">
                            <div v-for="group in question.groups" v-show="debug || group.display" :class="[{'hidden':!group.display}]" :data-group-id="group.id" :key="'qid_'+question.id+'_gid_'+group.id">

                                <template v-if="group.type=='number' || group.type=='textfield'">
                                    <v-list-item-title>
                                        {{group.text[language]}}
                                    </v-list-item-title>
                                    <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                                        <v-list-item-content style="width:200px;">
                                            <v-text-field v-model="form.group[group.id].value[choice.id]" v-show="choice.display" clearable :disabled="question.display===false || choice.disabled===true" :type="group.type" :onkeydown="group.type == 'number' ? 'javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))' : ''" :label="choice.text[language]"/>
                                            <v-text-field v-if="form.group[group.id].value[choice.id]!=null && form.group[group.id].value[choice.id].length>0 && choice.other" v-model="form.group[group.id].others[choice.id]" @blur="trim(group.id, choice.id)" :label="choice.other_text[language]"/>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <template v-else-if="group.type=='radio'">
                                    <v-list-item-title>
                                        {{group.text[language]}}
                                    </v-list-item-title>
                                    <v-radio-group v-model="form.group[group.id].value" class="pa-0">
                                        <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                                            <v-list-item-content class="pa-0">
                                                <v-radio dense v-show="choice.display" :disabled="question.display===false || choice.disabled===true" :value="choice.value ? choice.value : choice.id" :label="choice.text[language]"/>
                                                <v-text-field v-if="form.group[group.id].value==choice.value && choice.other" v-model="form.group[group.id].others[choice.id]" @blur="trim(group.id, choice.id)" :label="choice.other_text[language]"/>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-radio-group>
                                </template>

                                <v-container v-else-if="group.type=='rating'">
                                    <v-row>
                                        <v-col>
                                            {{group.text[language]}}
                                        </v-col>
                                        <v-col>
                                            <v-radio-group v-model="form.group[group.id].value" class="pa-0 d-none d-lg-block" :row="true" >
                                                <v-radio dense v-show="choice.display" :disabled="question.display===false || choice.disabled===true" :value="choice.value ? choice.value : choice.id" :label="choice.text[language]"  v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id"/>
                                            </v-radio-group>
                                            <v-select dense v-model="form.group[group.id].value" class="pa-0 d-lg-none"  :items="rankingItems(group)"/>
                                        </v-col>
                                    </v-row>

                                </v-container>

                                <template v-else-if="group.type=='checkbox'">
                                    <v-list-item-title>
                                        {{group.text[language]}}
                                    </v-list-item-title>
                                    <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                                        <v-list-item-title>
                                            <v-checkbox dense v-model="form.group[group.id].value" v-show="choice.display" :disabled="question.display===false || choice.disabled===true" :label="choice.text[language]" :value="choice.value ? choice.value : choice.id" @change="checkboxHandler(group, choice)"/>
                                            <v-text-field v-if="form.group[group.id].value.includes(choice.value) && choice.other" v-model="form.group[group.id].others[choice.id]" @blur="trim(group.id, choice.id)" :label="choice.other_text[language]"/>
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>

                                <template v-else>
                                    Unknown group type: {{group.type}}
                                </template>

                            </div>
                        </v-list> -->
                    </v-col>

                    <v-col cols="5" v-if="debug" style="font-size: 9pt;">
                        <template v-for="(group) in question.groups">
                            <template v-if="group.requirements">
                                <v-container :key="'debug_requirements_question_'+question.id+'_'+group.id">
                                    <v-row>
                                        <v-col cols="3">
                                            GROUP ID: {{group.id}} requirements
                                        </v-col>

                                        <v-col>
                                            <json-viewer :key="'debug_group_'+question.id+'_'+group.id" :value="group.requirements"/>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>

                            <template v-for="(choice) in group.choices">
                                <template v-if="choice.requirements">
                                    <v-container :key="'debug_requirements_question_'+question.id+'_'+group.id+'_'+choice.id">
                                        <v-row>
                                            <v-col cols="3">
                                                CHOICE ID: {{choice.id}} requirements
                                            </v-col>

                                            <v-col>
                                                <json-viewer :key="'debug_choice_'+question.id+'_'+group.id+'_'+choice.id" :value="choice.requirements"/>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </template>
                            </template>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import checkboxQuestion from './questionTypes/checkbox'
import radioQuestion from './questionTypes/radio'
import numberTextQuestion from './questionTypes/number_text'
import rankingQuestion from './questionTypes/ranking'
import ratingQuestion from './questionTypes/rating'


export default {
    name: "Question",
    components: {
        checkboxQuestion,
        radioQuestion,
        numberTextQuestion,
        rankingQuestion,
        ratingQuestion
    },
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        question: {
            type: Object,
            required: true
        },
        triggers: {
            type: Object,
            required: false,
            default: function(){
                return {
                    randomize: 0
                }
            }
        }
    },
    created: function(){
        this.setForm()
    },
    data: function(){
        return {
            form: {
                group: {}
            }
        }
    },
    methods: {
        getType: function(object){
            if(typeof object=='string' || typeof object=='number'){
                return typeof object
            }else if(object===null){
                return null
            }else if(typeof object=='object' && object.length!=undefined){
                return 'array'
            }else{
                return 'object'
            }

        },
        setForm: function(component){
            let self = component ? component : this
            let form = self.form
            let question = self.question
            for(let g=0; g<question.groups.length; g++){
                let group = question.groups[g]
                this.setGroup(form, group)
            }
            self.$forceUpdate()
        },
        setGroup: function(form, group){
            let self = this
            switch(group.type){
                default:
                    self.$set(form.group, group.id, {
                        value: null,
                        others: {}
                    })
                break;

                case"number":case"textfield":
                    let value = {}
                    let others = {}

                    for(let c=0; c<group.choices.length; c++){
                        let choice = group.choices[c]
                        value[choice.id] = null
                        others[choice.id] = null
                    }

                    self.$set(form.group, group.id, {
                        value,
                        others
                    })
                break;

                case"checkbox":case"ranking":
                    self.$set(form.group, group.id, {
                        value: [],
                        others: {}
                    })
                break;

            }

        },
        resetForm: function(){
            let groups = this.form.group

            for(let gid in groups){
                let form = groups[gid]

                if(form.value!=null){
                    if(['string','number'].includes(typeof form.value)){
                        form.value = null
                    }else if(typeof form.value=='object' && form.value.length!=undefined){
                        form.value = []
                    }else{
                        for(let i in form.value){
                            form.value[i] = null
                        }
                    }
                }
            }

        },
        inputForm: function(data){
            this.form.group[data.group.id] = data.form.group[data.group.id]
        },
        checkboxHandler: function(group, choice){

            if(this.form.group[group.id].value!=null && this.form.group[group.id].value.includes(choice.value)){
                if(choice.exclusive){
                    this.form.group[group.id].value = [choice.value]
                }else{
                    let exclusives = []
                    let filtered = []
                    for(let c=0; c<group.choices.length; c++){
                        let choice = group.choices[c]
                        if(choice.exclusive){
                            exclusives.push(choice.value)
                        }
                    }
                    for(let v=0; v<this.form.group[group.id].value.length; v++){
                        let value = this.form.group[group.id].value[v]
                        if(!exclusives.includes(value)){
                            filtered.push(value)
                        }
                    }
                    this.form.group[group.id].value = filtered
                }
            }
        },
        randomize: function(){
            function getRandomInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max)+1;
                return Math.floor(Math.random() * (max - min) + min);
            }

            if(this.question.display){
                let groups = this.question.groups
                for(let g=0; g<groups.length; g++){
                    let group = groups[g]
                    let gid = group.id
                    let choices = group.choices;

                    if(this.getType(this.form.group[group.id].value)=='array'){
                        this.form.group[group.id].value = []
                        for(let c=0; c<choices.length; c++){
                            let choice = choices[c]
                            if(getRandomInt(0, 1)==1){
                                this.form.group[group.id].value.push(choice.value)
                            }
                            this.checkboxHandler(group, choice)
                        }

                    }else if(this.getType(this.form.group[group.id].value)=='object'){
                        for(let cid in this.form.group[group.id].value){
                            let choiceRequirements = this.choiceMap[gid][cid]
                            let min = choiceRequirements.min ? choiceRequirements.min : 0
                            let max = choiceRequirements.max ? choiceRequirements.max : 100
                            this.form.group[group.id].value[cid] = getRandomInt(min, max).toString()
                        }
                    }else{
                        let randomChoice = choices[getRandomInt(0, choices.length-1)]
                        this.form.group[group.id].value = randomChoice.value
                    }
                }
            }
        },
        isValid: function(debug){
            function answerCount(answers){
                let counter = 0
                for(let cid in answers){
                    let value = answers[cid]
                    if(value!=null && value.length>0){
                        counter++
                    }
                }
                return counter
            }


            let valid = true
            //Validate value requirements
            // let requirements = this.question.requirements
            let question = this.question
            let validationType = undefined
            for(let g=0; g<question.groups.length; g++){
                let group = question.groups[g]
                let gid = group.id
                let groupRequirements = group.requirements
                if(groupRequirements && groupRequirements.required){



                    //No answer
                    if((this.form.group[gid].value==null || this.form.group[gid].value.length==0)){
                        validationType = {rule: 'no answer', category: ''}
                        valid = false
                    }

                    //Answers -> array eg. [checkbox,ranking]
                    if(this.getType(this.form.group[gid].value)=='array'){

                        //Min,Max selections
                        if(groupRequirements.min!=undefined && this.form.group[gid].value.length<groupRequirements.min){
                            validationType = {rule: 'min', category: '[checkbox, ranking]'}
                            valid = false
                        }
                        if(groupRequirements.max!=undefined && this.form.group[gid].value.length>groupRequirements.max){
                            validationType = {rule: 'max', category: '[checkbox, ranking]'}
                            valid = false
                        }

                        //Required selection(s)
                        for(let c=0; c<group.choices.length; c++){
                            let choice = group.choices[c]
                            let cid = choice.id
                            let choiceRequirements = choice.requirements
                            let choiceValue = this.choiceMap[gid][cid].value

                            //Required choice
                            if(choiceRequirements && choiceRequirements.required && !(this.form.group[gid].value.includes(choiceValue))){
                                validationType = {rule: 'choice', category: 'required'}
                                valid = false

                            }

                        }

                    }

                    //Answers -> object group eg. [numbers , textfield]
                    if(this.getType(this.form.group[gid].value)=='object'){
                        let counter = answerCount(this.form.group[gid].value)
                        if(groupRequirements.min!=undefined && counter<groupRequirements.min){
                            validationType = {rule: 'min', category: 'group - [numbers , textfield]'}
                            valid = false
                        }
                        if(groupRequirements.max!=undefined && counter>groupRequirements.max){
                            validationType = {rule: 'max', category: 'group - [numbers , textfield]'}
                            valid = false
                        }

                        for(let c=0; c<group.choices.length; c++){
                            let choice = group.choices[c]
                            let cid = choice.id

                            let choiceRequirements = choice.requirements //requirements.choices[cid]
                            let value = this.form.group[gid].value[cid]

                            //Min,Max Values
                            if(choiceRequirements && value!=null && value.length>0){
                                value = parseFloat(this.form.group[gid].value[cid],10)
                                if(choiceRequirements.min!=undefined && value<choiceRequirements.min){
                                    validationType = {rule: 'min', category: 'group > choice - [numbers , textfield]'}
                                    valid = false
                                }
                                if(choiceRequirements.max!=undefined && value>choiceRequirements.max){
                                    validationType = {rule: 'max', category: 'group > choice - [numbers , textfield]'}
                                    valid = false
                                }
                            }
                        }

                    }

                    //Min,Max - value eg. [radio]
                    if(this.getType(this.form.group[gid].value)=='string'){
                        if(groupRequirements.min!=undefined && parseFloat(this.form.group[gid].value,10)<groupRequirements.min){
                            validationType = {rule: 'min', category: '[radio]'}
                            valid = false
                        }
                        if(groupRequirements.max!=undefined && parseFloat(this.form.group[gid].value,10)>groupRequirements.max){
                            validationType = {rule: 'max', category: '[radio]'}
                            valid = false
                        }
                    }

                }
            }

            //Validate Other
            for(let gid in this.form.group){
                let group = this.form.group[gid]

                if(group.value!=null){

                    if(typeof group.value=='object' && group.value.length!=undefined){
                        //Handling [checkboxes, ranking]
                        for(let c=0; c<group.value.length; c++){
                            let choice = this.valueMap[gid][group.value[c]]
                            if(choice.other && !group.others[choice.id]){
                                validationType = {group: gid, formValue: this.form.group[gid].value, rule:'other', category: '[checkboxes, ranking]'}
                                valid = false
                            }
                        }
                    }else if(typeof group.value=='object' && group.value.length==undefined){
                        //Handling [numbers, text]
                        for(let cid in group.value){
                            let choice = this.choiceMap[gid][cid]
                            if(choice.other && !group.others[cid] && (group.value[cid]!=null && group.value[cid].length>0)){
                                validationType = {group: gid, formValue: this.form.group[gid].value, rule:'other', category: '[numbers, text]'}
                                valid = false
                            }
                        }

                    }else{
                        //Handling radios
                        let choice = this.valueMap[gid][group.value]
                        if(choice.other && !group.others[choice.id]){
                            validationType = {group: gid, formValue: this.form.group[gid].value, rule:'other', category: '[radio]'}
                            valid = false
                        }
                    }

                }
            }

            if(debug){
                console.table({
                    question: question.id,
                    valid,
                    validationType
                })
            }


            return valid
        },
        trim: function(group_id, choice_id){
            this.form.group[group_id].others[choice_id] = this.form.group[group_id].others[choice_id].trim()
        },
        rankingItems: function(group){
            let language = this.language
            let output = []

            for(let c=0; c<group.choices.length; c++){
                let choice = group.choices[c]
                output.push({
                    text: choice.text[language] ? choice.text[language] : choice.text['en-ca'],
                    value: choice.value,
                    disabled: group.disabled || choice.disabled
                })
            }

            return output
        }
    },
    computed: {
        displayed: function(){
            return this.question.display===true
        },
        groupDisplay: function(){
            let output = {}
            let groupMap = this.groupMap
            for(let g in groupMap){
                output[g] = {
                    displayed: groupMap[g].display,
                    choice: {}
                }

                for(let c=0; c<groupMap[g].choices.length; c++){
                    let choice = groupMap[g].choices[c]
                    output[g].choice[choice.id] = choice.display
                }
            }

            return output
        },
        hasDisplayLogics: function(){
            return this.question.displayLogic!=undefined
        },
        language: function(){
            return this.$store.getters.language
        },
        randomizeTrigger: function(){
            return this.triggers.randomize
        },
        resetTrigger: function(){
            return this.triggers.reset
        },
        groupMap: function(){
            let output = {}
            let groups = this.question.groups
            for(let g=0; g<groups.length; g++){
                let group = groups[g]
                output[group.id] = group
            }

            return output
        },
        choiceMap: function(){
            let output = {}
            let groups = this.question.groups
            for(let g=0; g<groups.length; g++){
                let group = groups[g]
                let choices = group.choices
                output[group.id] = {}

                for(let c=0; c<choices.length; c++){
                    let choice = choices[c]
                    output[group.id][choice.id] = choice
                }
            }

            return output
        },
        valueMap: function(){
            let output = {}
            let groups = this.question.groups
            for(let g=0; g<groups.length; g++){
                let group = groups[g]
                let choices = group.choices
                output[group.id] = {}

                for(let c=0; c<choices.length; c++){
                    let choice = choices[c]
                    output[group.id][choice.value] = choice
                }
            }

            return output
        },
        promptMap: function(){
            let prompts = this.question.prompts
            let output = {}
            for(let i=0; i<prompts.length; i++){
                let prompt = prompts[i]
                output[prompt.prompt_id] = prompt
            }

            return output
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                for(let gid in this.form.group){
                    let group = this.form.group[gid]
                    let others = group.others
                    for(let cid in others){
                        let other = others[cid]
                        let choice = this.choiceMap[gid][cid]

                        if(choice.other){
                            let deleteOther = false
                            if((other!=null && other.length==0)){
                                deleteOther = true
                            }

                            if(group.value===null){
                                deleteOther = true
                            }else if(group.value.length!=undefined){
                                if( !(group.value==choice.value || group.value.includes(choice.value)) ){
                                    deleteOther = true

                                }
                            }else{
                                if( !(group.value[cid]!=null && group.value[cid].length>0) ){
                                    deleteOther = true
                                }
                            }


                            if(deleteOther){
                                this.form.group[gid].others[cid] = null
                            }
                        }

                    }
                }

                let answered = {}
                for(let gid in this.form.group){
                    let group = this.form.group[gid]

                    if(group.value!=null){
                        answered[gid] =
                            typeof group.value=='number' ||
                            typeof group.value=='string' && group.value.length>0 ||
                            typeof group.value=='object' && group.value.length>0
                    }else{
                        answered[gid] = false
                    }
                }
                this.question.answered = answered


                this.question.valid = this.isValid()
                this.$emit('input',this.form)
                this.$emit('questionUpdate',this.question)
            }
        },
        displayed: function(){
            if(!this.displayed){
                this.setForm()
            }
        },
        choiceMap: {
            deep: true,
            handler: function(){
                for(let gid in this.form.group){
                    let group = this.form.group[gid]

                    if(group.value!=null && typeof group.value=='object'){
                        if(group.value.length==undefined){
                            for(let cid in group.value){
                                let choice = this.choiceMap[gid][cid]
                                if(choice.disabled===true){
                                    group.value[cid] = null
                                }
                            }
                        }else{
                            for(let c=0; c<group.value.length; c++){
                                let value = group.value[c]
                                let choice = this.valueMap[gid][value]
                                if(choice.disabled===true){
                                    group.value.splice(c,1)
                                }
                            }
                        }
                    }else if(group.value!=null){
                        let value = group.value
                        let choice = this.valueMap[gid][value]
                        if(choice.disabled===true){
                            group.value = null
                        }
                    }

                }
            }
        },
        randomizeTrigger: function(){
            this.randomize()
        },
        resetTrigger: function(){
            this.resetForm()
        },
        groupDisplay: function(){
            let form = this.form
            let groupDisplay = this.groupDisplay
            let choiceMap = this.choiceMap
            for(let gid in groupDisplay){
                if(!groupDisplay[gid].displayed){
                    this.setGroup(form, this.groupMap[gid])
                }else{
                    for(let cid in groupDisplay[gid].choice){
                        let choice = choiceMap[gid][cid]
                        if(!groupDisplay[gid].choice[cid]){
                            if(form.group[gid].value!=null && typeof form.group[gid].value=='object' && form.group[gid].value.length!=undefined){
                                for(var f=0; f<form.group[gid].value.length; f++){
                                    if(form.group[gid].value[f]==choice.value){
                                        form.group[gid].value.splice(f,1)
                                    }
                                }
                            }else if(form.group[gid].value!=null && typeof form.group[gid].value=='object' && form.group[gid].value.length==undefined){
                                for(let i in form.group[gid].value){
                                    form.group[gid].value[i] = null
                                }
                            }else{
                                form.group[gid].value = null
                            }
                        }
                    }

                }

            }
        }
    }
}
</script>

<style scoped>
.question .v-list-item__content, .question .v-list-item__title, .question .v-list-item__subtitle{
    overflow: visible !important;
}

.hidden{
    color: darkgrey;
    background-color: whitesmoke;
}

.hasDisplayLogics{
    border: 1px solid lightgrey;
}

.valid{
    border: 1px solid darkgreen;
}

.invalid{
    border: 1px solid red;
}
</style>

<style>
.jv-container.jv-light{
    background-color: transparent;
}
.jv-container .jv-code{
    padding: 0px !important;
}
</style>

<style lang='scss'>

    .question-container {

        // change white space rule for question text so it wraps insead of being cut-off
        > .container.question {
            .question-group {
                .v-list-item__title {
                    white-space: normal;
                }
            }
        }

        // remove padding from different wrappers so that questions squished thinly on small mobile screens
        @media(max-width:575px) {
            margin: 15px 0 !important;

            > .container.question {
                padding: 12px 0;

                .col:first-of-type {
                    > .container {
                        padding: 12px 0;
                    }
                }
            }
        }

        // rule to bring question-subtext to proper position
        > .container.question {
            .question-group {
                .container {
                    .container {
                        .row {
                            > div:first-of-type {
                                > div {
                                    margin: 0 16px 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
