<template>
<div>    
    <v-list>
        <v-list-item-title>
            {{group.text[language]}}
        </v-list-item-title>
        <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
            <v-list-item-content style="width:200px;">
                <v-text-field v-model="form.value[choice.id]" v-show="choice.display" clearable :disabled="question.display===false || choice.disabled===true" :type="group.type" :onkeydown="group.type == 'number' ? 'javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))' : ''" :label="choice.text[language]"/>
                <!-- <v-text-field v-if="form.value[choice.id]!=null && form.value[choice.id].length>0 && choice.other" v-model="form.others[choice.id]" @blur="trim(group.id, choice.id)" :label="choice.other_text[language]"/> -->
                <other v-if="form.value[choice.id]!=null && form.value[choice.id].length>0 && choice.other" v-model="form.others[choice.id]" :label="choice.other_text[language]"/>
            </v-list-item-content>
        </v-list-item>  
    </v-list>
</div>
</template>

<script>
import other from './other'
export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        question: {
            type: Object,
            required: true
        },
        group: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        other
    },
    created: function(){
        this.form = this.$attrs.value
    },
    data: function(){
        return {
            form: {}
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.$emit('input',this.form)
            }
        }
    }
}
</script>

<style>

</style>